import { useState } from "react";

export const SetLocalStorage = (name: string, value: any) => {
    localStorage.setItem(name, value)
}

export const GetLocalStorage = (name: string) => {
    return localStorage.getItem(name)
}

export const RemoveLocalStorage = (name: string) => {
    localStorage.removeItem(name)
}

export const SetCookie = (name: string, value: any, days?: number, hour?: number, minutes?: number) => {
    var expires = "";
    var date = new Date();
    if (days) {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    if (hour) {
        date.setTime(date.getTime() + (hour * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }

    if (minutes) {
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const GetCookie = (name: string) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function NumberWithCommas(num?: any, digit?: number) {
    if (num) {
        num = num.toFixed(digit ? digit : 0).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }
    return 0;
}

export const setLastName = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nameUser, setNameuser] = useState<string>()

    return {
        nameUser,
        setNameuser,
    }
}

export const genrateRandomNumber = (min: number, max: number) => {
    const numberMin: number = Math.ceil(min);
    const numberMax: number = Math.floor(max);
    return Math.floor(Math.random() * (numberMax - numberMin + 1)) + numberMin;
}

export const dateTH = (y: number, m: number, d: number) => {
    const date = new Date(y, m, d)
    const result = date.toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
    return result
}

export const isMobile = () => /android|ip(hone|ad|od)/i.test(window.navigator.userAgent)