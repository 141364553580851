import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import './app.scss'
import 'antd/dist/antd.css'
import 'sweetalert2/src/sweetalert2.scss'

import { Provider } from 'react-redux';
import store from '../src/store';
import Login from './views/Login'
import Main from './components/MainLayout/Main'

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="*" element={<Main />} />
        </Routes>
      </BrowserRouter >
    </Provider>
  )
}

export default App;
