import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { NumberWithCommas } from '../../services/function'

const dataOjects = {
    data: [
        {
            key: '1',
            id: '1',
            number: '100001',
            credit: 1000,
            type: 'ฝาก',
            status: 'รอการยืนยัน',
            description: '*บลา บลา บลา บลา บลา',
        },
        {
            key: '2',
            id: '2',
            number: '100002',
            credit: 200,
            type: 'ถอน',
            status: 'รอการยืนยัน',
            description: '*บลา บลา บลา บลา บลา',
        },
        {
            key: '3',
            id: '3',
            number: '100003',
            credit: 32000,
            type: 'ถูกรางวัล',
            status: 'สำเร็จ',
            description: '*บลา บลา บลา บลา บลา',
        },
        {
            key: '4',
            id: '4',
            number: '100004',
            credit: 500,
            type: 'ฝาก',
            count: '3',
            status: 'ยกเลิก',
            description: '*บลา บลา บลา บลา บลา',
        },
    ]
}

const columns = [
    {
        title: '#',
        render: (row: any, key: number) => row.id,
        width: '72px',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.id - b.id,
    },
    {
        title: 'เลขที่การสั่งซื้อ',
        width: '10%',
        render: (row: any) => row.number,
    },
    {
        title: 'จำนวนเครดิต',
        width: '20%',
        render: (row: any) => {
            return NumberWithCommas(row.credit)
        },
    },
    {
        title: 'ประเภท',
        width: '20%',
        render: (row: any) => row.type,
    },
    {
        title: 'สถานะ',
        width: '20%',
        render: (row: any) => row.status,
    },
    {
        title: 'หมายเหตุ',
        width: '30%',
        render: (row: any) => row.description,
    },
]

const History = () => {
    return (
        <>
            <div className="pt-3 pt-lg-5">
                <div className="card-content">
                    <div className="row">
                        <div className='col-12'>
                            <h3 className='header-title'>ประวัติการใช้เครดิต</h3>
                        </div>
                    </div>
                </div>
            </div>

            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <Table
                    columns={columns as any}
                    dataSource={dataOjects.data}
                    rowKey={record => record.id}
                    // loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                // onChange={ScrollToTopPagination}
                // pagination={{
                //     defaultPageSize: 20,
                //     showSizeChanger: true,
                //     pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                //     position: ['topRight', 'bottomRight'],
                //     locale: { items_per_page: " รายการ" }
                // }}
                />
            </ListTableCard>
        </>
    )
}

export default History

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`