import { MemberResponse } from '../@types/member'
import api from '../helpers/api'

interface LoginData {
	username: string
	password: string
}
interface LoginResponse {
	token: string
}

class AuthService {
	private token: string = ''
	private member?: MemberResponse

	constructor() {
		if (typeof window !== 'undefined') {
			const token = localStorage.getItem('golden-auth')
			if (token) {
				this.setToken(token)
			}
		}
	}

	setToken(token: string) {
		this.token = token
		localStorage.setItem('golden-auth', token)
	}

	revokeToken() {
		this.token = ''
		localStorage.removeItem('golden-auth')
	}

	async getMemberInfo(refetch: boolean = false): Promise<MemberResponse> {
		if (!this.token) {
			throw new Error('INVALID AUTH STATE')
		}

		if (this.member && !refetch) {
			return this.member
		}

		try {
			const res = await api.get<void, MemberResponse>('v1/mm/session')
			this.member = res
			return this.member
		} catch (e) {
			throw e
		}
	}

	async login(data: LoginData, token?: any, isToken?: true): Promise<MemberResponse> {
		if (isToken) {
			try {
				this.setToken(token)
				return this.getMemberInfo(true)
			} catch (e) {
				throw e
			}
		} else {
			try {
				const res = await api.post<void, LoginResponse>('v1/mm/login', data)
				this.setToken(res.token)
				return this.getMemberInfo(true)
			} catch (e) {
				throw e
			}
		}
	}
}

export default new AuthService()
