import { Empty, Skeleton, Table } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import useDatePagination from '../../utils/useDatePagination'
import useFetchOrder from '../../hook/useFetchOrder'
import ViewUser from '../../components/Users/ViewUser'

const Order = () => {

    const [orderDetail, setOrderDetail] = useState('')
    const handleEditOrderDetail = (object: any) => setOrderDetail(object)

    const [viewOrderDetailDrawer, setViewOrderDetailDrawer] = useState(false)
    const toggleViewOrderDetail = () => setViewOrderDetailDrawer(!viewOrderDetailDrawer)

    const { pagination, setPagination } = useDatePagination()
    const [dataSource, setDataSource] = useState<any>([])
    const { data, mutate, isLoading } = useFetchOrder({
        page: pagination.page,
        limit: pagination.pageSize,
    })

    const columns = [
        {
            title: 'เลขที่การสั่งซื้อ',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div
                            className="btn-link-view"
                            onClick={() => {
                                handleEditOrderDetail(row)
                                toggleViewOrderDetail()
                            }}>
                            {row.id}
                        </div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'ชื่อ - นามสกุล',
            render: (row: any) => row.name,
        },
        {
            title: 'จำนวน',
            width: '10%',
            render: (row: any) => row.totalNumber,
        },
        {
            title: 'สถานะ',
            width: '20%',
            render: (row: any) => {
                if (row.status === 'NEW') {
                    return <p style={{ color: '#ffc107' }}>รอการยืนยัน</p>
                }
                if (row.status === 'Paid') {
                    return <p style={{ color: '#198754' }}>สำเร็จ</p>
                }
                if (row.status === 'fail') {
                    return <p style={{ color: '#dc3545' }}>ยกเลิก</p>
                }
                return row.status
            },
        },
        {
            title: 'วันที่สร้าง',
            render: (row: any) => row.createdAt,
        }
    ]

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    return (
        <>
            <div className="pt-3 pt-lg-5">
                <div className="card-content">
                    <div className="row">
                        <div className='col-12'>
                            <h3 className='header-title'>บิลที่สั่งซื้อ</h3>
                        </div>
                    </div>
                </div>
            </div>

            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <Table
                    columns={columns as any}
                    dataSource={dataSource || []}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }}
                    sticky={{ offsetHeader: window.screen.width > 992 ? 87 : 0 }}
                    pagination={
                        {
                            size: "default",
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ['25', '50', '100', '200'],
                            position: ['bottomRight'],
                            locale: { items_per_page: " / Page" },
                            total: data?.totalCount || 0,
                            current: pagination.page || 1,
                            showLessItems: (window.screen.width < 768 ? true : false),
                            onChange: (page: number, pageSize: number) => {
                                setPagination && setPagination({ page: page, pageSize: pageSize })
                            }
                        }
                    }
                />

                <ViewUser
                    order={orderDetail as any}
                    isOpenViewDrawer={viewOrderDetailDrawer}
                    closeViewDrawer={() => toggleViewOrderDetail()}
                    swapDrawerEdit={() => {
                        toggleViewOrderDetail()
                    }}
                />
            </ListTableCard>
        </>
    )
}

export default Order

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`