import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer, { defaultState as userDefaultState, State as UserState } from './user/user.reducer'

export interface ApplicationState {
	user: UserState
}

export const defaultState: ApplicationState = {
	user: userDefaultState,
}

const reducers = combineReducers({
	user: userReducer
})


const enhancers = compose(
	process.env.NODE_ENV !== 'production' ? composeWithDevTools(
		applyMiddleware(thunk),
	) : (f: any) => f
) as any

export default createStore(reducers, defaultState, enhancers)
