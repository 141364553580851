import React, { Fragment, useEffect, useState } from 'react'

import { Checkbox, Col, Form, Input, Row } from 'antd'
import { numberItems } from '../../utils/000-999'
import serviceAPI from '../../services/serviceAPI'
import { dateTH, NumberWithCommas } from '../../services/function'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

// import moment from 'moment';

const Number = () => {

  const userState = useSelector((state: ApplicationState) => state.user)

  const [formSearch] = Form.useForm();
  const [formNumber] = Form.useForm();
  const [formInfo] = Form.useForm();
  const [formCheckedNumber] = Form.useForm();

  const [numberItemChrcked, setNumberItemChecked] = useState<any>([])
  const [resDataLive, setResDataLive] = useState<any>([])
  const [dateRound, setDateRound] = useState<any>()
  const [dateMyChart, setDateMyChart] = useState<any>([])
  const [sumPrice, setSumPrice] = useState<any>(0)
  const [sumPriceBeNet, setSumPriceBeNet] = useState<any>(0)
  const [sumPriceNet, setSumPriceNet] = useState<any>(0)

  const onLoadMyChartNumber = (values: any) => {
    const _numberItemChrcked: any = []
    values.map((item: any, index: number) => { //eslint-disable-line
      _numberItemChrcked.push(<Checkbox key={`num-` + item} value={item} style={{ lineHeight: '32px', borderRadius: '10px' }} >{item}</Checkbox>)
    })
    setNumberItemChecked(_numberItemChrcked)
  }

  const onValuesChangeNumber = (value: any) => {
    if (value.target.checked) {
      addChart(resDataLive.data?.id, value.target.value)
    } else {
      removeChart(resDataLive.data?.id, value.target.value)
    }
  }

  const getLive = async () => {
    try {
      const res = await serviceAPI.getLive()
      setResDataLive(res)
    } catch ({ message }) {
      console.log(message)
    }
  }

  const addChart = async (roundid: string, number: number) => {
    try {
      const resAddChart = await serviceAPI.addChart(roundid, number) //eslint-disable-line
      getChart()
    } catch ({ message }) {
      console.log(message);
    }
  }

  const removeChart = async (roundid: string, number: number) => {
    try {
      const resRemoveChart = await serviceAPI.removeChart(roundid, number) //eslint-disable-line
      getChart()
    } catch ({ message }) {
      console.log(message);
    }
  }

  const getChart = async () => {
    try {
      const resMyChart = await serviceAPI.getChart()
      setDateMyChart(resMyChart)
      onLoadMyChartNumber(resMyChart.data?.numbers)
      sumPriceChart(resMyChart)
    } catch ({ message }) {
      console.log(message);
    }
  }

  const hendalSearch = () => {
    formSearch.validateFields()
      .then(async (values: any) => {
        try {
          if (document.getElementById(`number-` + values.number)?.parentElement?.className.split(" ")?.includes('ant-checkbox-checked')) {
            Swal.fire({
              title: 'คุณได้เลือกเลขนี้แล้ว',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ตกลง'
            })
          } else {
            const resStock = await serviceAPI.getLiveStock(values.number)
            if (resStock?.stock !== 0) {
              document.getElementById(`number-` + values.number)?.click()
            } else {
              Swal.fire({
                title: 'เลขนี้มีท่านอื่นเลือกแล้ว',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง'
              })
            }
          }
        } catch ({ message }) {
          console.log(message);
        }
      }).catch(({ message }: any) => {
        console.log(message);
      });
  }

  const confrimChart = async (ChartId: any) => {
    if (ChartId.length !== 0 || ChartId?.data?.id) {
      formInfo.validateFields()
        .then(async (values: any) => {
          try {
            const resConfirmChart = await serviceAPI.confirmChart(ChartId?.data?.id, values.name, values.phone, values.contact) //eslint-disable-line
            Swal.fire({
              title: 'ดำเนินการสำเร็จ?',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'ตกลง',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          } catch ({ message }) {
            console.log(message);
          }
        }).catch(({ message }: any) => {
          Swal.fire({
            title: 'กรุณากรอกข้อมูลผู้ซื้อให้เรียบร้อย !',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ตกลง'
          })
        });
    } else {
      Swal.fire({
        title: 'กรุณาเลือกเลข แล้วลองใหม่อีกครั้ง !',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ตกลง'
      })
    }
  }

  const sumPriceChart = (valueChart: any) => {
    const numberOfChart = valueChart.length === 0 ? 0 : valueChart?.data?.numbers.length
    const price = (resDataLive?.data?.price * numberOfChart)
    const discount  = (price) * 20 / 100

    setSumPrice(price)
    setSumPriceBeNet(discount)
    setSumPriceNet(price - discount)
  }

  useEffect(() => {
    getLive()
    getChart()
    if (resDataLive.data?.date !== undefined) {
      const myDate = resDataLive.data?.date.split("-");
      setDateRound(dateTH(myDate[0], myDate[1], myDate[2]))
    }
  }, [resDataLive.data?.date]) //eslint-disable-line

  const numberItem: any = []
  if (resDataLive.data?.purchasedNumber !== undefined) {
    numberItems.map((item: any, index: number) => { //eslint-disable-line
      if (resDataLive.data?.purchasedNumber.includes(item.key)) {
        numberItem.push(<Checkbox key={item.key} id={`number-` + item.key} value={item.key} style={{ lineHeight: '32px', borderRadius: '10px' }} disabled>{item.key}</Checkbox>)
      } else {
        numberItem.push(<Checkbox onChange={onValuesChangeNumber} key={item.key} id={`number-` + item.key} value={item.key} style={{ lineHeight: '32px', borderRadius: '10px' }}>{item.key}</Checkbox>)
      }
    })
  }

  return (
    <Fragment>
      <div className="pt-3 pt-lg-5">
        <div className="card-content">
          <div className="row">
            <div className='col-12'>
              <h3 className='header-title'>ออกบิลใหม่</h3>
            </div>
          </div>
          <div className="row">
            <div className='col-12'>
              <p className='sub-title'>งวดวันที่ {dateRound}</p>
            </div>
          </div>
        </div>

        <div className="card-search">
          <Form
            form={formSearch}
            name="urlForm"
          >
            <Row gutter={24}>
              <Col span={20}>
                <Form.Item
                  name="number"
                  rules={[{ required: true, message: '' }, { type: 'string', min: 3, max: 3 }]}
                >
                  <Input style={{ height: 50 }} placeholder="กรอกเลขที่ต้องการค้นหา" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary" onClick={hendalSearch}><i className="far fa-money-bill-wave-alt"></i> ค้นหา</button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="card-number">
          <Form
            form={formNumber}
            layout="horizontal"
          >
            <Form.Item name="checkboxGroup">
              <Checkbox.Group style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                {numberItem}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>

        <div className="card-checked-number">
          <Form
            form={formCheckedNumber}
            layout="horizontal"
          >
            <Form.Item name="checkedboxGroup">
              <Checkbox.Group style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                {numberItemChrcked}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>

        <div className="card-des-info">
          <Form
            form={formInfo}
            name="urlForm"
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input style={{ height: 50 }} placeholder="กรอกชื่อ (ผู้ซื้อ)" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input style={{ height: 50 }} placeholder="กรอกเบอร์โทร (ผู้ซื้อ)" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="contact"
                  rules={[{ required: true, message: '' }]}
                >
                  <Input style={{ height: 50 }} placeholder="กรอกข้อมุลติดต่อ (ผู้ซื้อ) เช่น LineID" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="card-content">
          <div className="row">
            <div className='col-6'>
              <p>เครดิตคงเหลือ:</p>
            </div>
            <div className='col-6'>
              <p className='number-credit-balance' >{NumberWithCommas(userState?.info?.balance)}</p>
            </div>
          </div>
          <div className="row">
            <div className='col-6'>
              <p>จำนวนเลข:</p>
            </div>
            <div className='col-6'>
              <p className='number-credit-balance' >{dateMyChart.length === 0 ? 0 : dateMyChart?.data?.numbers.length}</p>
            </div>
          </div>
          <div className="row">
            <div className='col-6'>
              <p>ยอดซื้อรวม:</p>
            </div>
            <div className='col-6'>
              <p className='number-credit-balance' >{NumberWithCommas(sumPrice)}</p>
            </div>
          </div>
          <div className="row">
            <div className='col-6'>
              <p style={{ color: '#dc3545' }}>ส่วนลด (-20%):</p>
            </div>
            <div className='col-6'>
              <p className='number-credit-balance' style={{ color: '#dc3545' }}>{NumberWithCommas(sumPriceBeNet)}</p>
            </div>
          </div>
          <div className="row">
            <div className='col-6'>
              <p style={{ color: '#198754' }}>ยอดสุทธิ:</p>
            </div>
            <div className='col-6'>
              <p className='number-credit-balance' style={{ color: '#198754' }}>{NumberWithCommas(sumPriceNet)}</p>
            </div>
          </div>
        </div>
      </div >
      <div className="row" style={{ marginBottom: '8%', marginTop: '5%' }}>
        <div className='col-6' style={{ textAlign: 'end' }}>
          <button type="submit" className="btn btn-primary" onClick={() => { confrimChart(dateMyChart) }}><i className="far fa-money-bill-wave-alt"></i> ยืนยัน</button>
        </div>
        <div className='col-6'>
          <button type="submit" className="btn btn-dangerous"><i className="far fa-money-bill-wave-alt"></i> ยกเลิก</button>
        </div>
      </div>
    </Fragment >
  )
}

export default Number