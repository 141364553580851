import api from '../helpers/api'

import { APIResponse } from '../@types/api'
import { Live } from '../@types/round'

class serviceAPI {

	getLive(): Promise<Live> {
		return api.get<void, Live>(`v1/mm/rounds/live`)
	}

	getLiveStock(number: number): Promise<any> {
		return api.get<void, any>(`v1/mm/rounds/live/${number}/stock`)
	}

	addChart(roundId: any, number: number): Promise<APIResponse> {
		return api.post<void, APIResponse>('v1/mm/carts/add',
			{
				roundId: roundId,
				number: number
			}
		)
	}

	getChart(): Promise<any> {
		return api.get<void, any>(`v1/mm/carts`)
	}

	removeChart(roundId: any, number: number): Promise<APIResponse> {
		return api.delete<void, APIResponse>(`v1/mm/carts/remove`,
			{
				data: {
					roundId: roundId,
					number: number
				}

			}
		)
	}

	confirmChart(cartId: string, name: string, phone: any, contact: string): Promise<APIResponse> {
		return api.post<void, APIResponse>('v1/mm/carts/confirm',
			{
				cartId: cartId,
				name: name,
				phone: phone,
				contact: contact
			}
		)
	}

	getOrder(limit: any, page: any): Promise<any> {
		return api.get<void, any>(`v1/mm/orders?limit=${limit}&page=${page}`)
	}
}

export default new serviceAPI()