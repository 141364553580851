import React, { useState } from 'react'

import styled from 'styled-components';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import logo from './../../assets/img/logo/logo.svg'

import { useDispatch } from 'react-redux'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions';

const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [isPassword, setIsPassword] = useState(true);
  const togglePasswordInput = () => setIsPassword(!isPassword)

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const res = await AuthService.login({
        username: username,
        password: password,
      })
      dispatch(setUser(res))

      navigate('/home')
    } catch ({ message }) {
      console.log(message);
    }
  }

  return (
    <Wrapper>
      <SignIn>
        <form onSubmit={handleSubmit}>
          <img className="mb-5 d-block mx-auto" src={logo} alt="logo Expense" width="180" />
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingInput" placeholder="Username" onChange={e => setUsername(e.target.value)} value={username} required />
            <label>ชื่อผู้ใช้งาน</label>
          </div>

          <div className="form-floating mb-3">
            <input type={isPassword ? 'password' : 'text'} className="form-control" id="floatingPassword" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} required />
            <label>รหัสผ่าน</label>
            <TogglePassword onClick={togglePasswordInput}>
              {isPassword ? <EyeOutlined style={{ fontSize: '24px' }} /> : <EyeInvisibleOutlined style={{ fontSize: '24px' }} />}
            </TogglePassword>
          </div>

          <button className="w-100 btn btn-lg btn-primary" type="submit">เข้าสู่ระบบ</button>
          <p className="mt-5 mb-3 text-center text-muted"><small>Copyright © 2023 <a href="/">Golden</a>. All rights reserved.</small></p>
        </form>
      </SignIn>
    </Wrapper>

  )
}

export default Login

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f4effe;
`;

const SignIn = styled.main`
	width: 100%;
	max-width: 400px;
	padding: 15px;

	a {
		color: #c26aff;
	}
`;

const TogglePassword = styled.div`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    opacity: 0.5;
    transition: all 0.25s ease;

    &:hover {
        opacity: 1;
    }
`;