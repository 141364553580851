import React, { FC, useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store';
import AuthService from '../../services/auth'
import { requestUserDone, requestUserFailed } from '../../store/user/user.actions';
import { Navigate } from 'react-router-dom';

interface AuthContainerPros {
    children: React.ReactNode;
}

const AuthContainer: FC<AuthContainerPros> = ({ children }) => {

    const dispatch = useDispatch()
	const userState = useSelector((state: ApplicationState) => state.user)

	const requestAuthState = useCallback(async () => {
		try {
			const res = await AuthService.getMemberInfo()
			dispatch(requestUserDone(res))
		} catch {
			localStorage.removeItem('golden-auth')
			dispatch(requestUserFailed())
		}
	}, [dispatch])
	useEffect(() => {
		requestAuthState()
	}, [requestAuthState])

	if (userState.isLoaded && !userState.info) {
		return <Navigate to="/login" replace/>
	}

	return <>{children}</>
}

export default AuthContainer