import React from 'react'

import { Col, Form, Input, Row, Select } from 'antd'
import { Option } from 'antd/lib/mentions';

const Deposit = () => {

    const [form] = Form.useForm();

    const hendalDeposit = async () => {
        form.validateFields()
            .then(async (values: any) => {
                try {
                    console.log(values);
                } catch ({ message }) {
                    console.log(message);
                }
            }).catch(({ message }: any) => {
                console.log(message);
            });
    }

    return (
        <>
            <div className="pt-3 pt-lg-5">
                <div className="card-content">
                    <div className="row">
                        <div className='col-12'>
                            <h3 className='header-title'>เติมเครดิต</h3>
                        </div>
                    </div>
                </div>

                <div className="card-deposit-withdraw">
                    <Form
                        form={form}
                        name="urlForm"
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="type"
                                    rules={[{ required: true, message: 'กรุณาเลือกช่องทางการเติมเครกิต' }]}
                                >
                                    <Select style={{ height: 50 }} placeholder="ช่องทางการเติมเครกิต">
                                        <Option value="Qrcode">Qrcode</Option>
                                        <Option value="True Wallet">True Wallet</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="amount"
                                    rules={[{ required: true, message: '' }, { type: 'string', min: 3, max: 3 }]}
                                >
                                    <Input style={{ height: 50 }} placeholder="กรอกจำนวนเครดิต" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <div className="text-end">
                                    <button type="submit" className="btn btn-primary" onClick={hendalDeposit}><i className="far fa-money-bill-wave-alt"></i> ยืนยัน</button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Deposit