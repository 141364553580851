import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { NumberWithCommas } from '../../services/function'

const dataOjects = {
    data: [
        {
            key: '1',
            id: '1',
            number: '100001',
            name: 'Jim Green',
            count: '1',
            status: 'รอการยืนยัน',
            credit: 32000,
        },
        {
            key: '2',
            id: '2',
            number: '100002',
            name: 'John Brown',
            count: '4',
            status: 'รอการยืนยัน',
            credit: 32000,
        },
        {
            key: '3',
            id: '3',
            number: '100003',
            name: 'Joe Black',
            count: '5',
            status: 'สำเร็จ',
            credit: 32000,
        },
        {
            key: '4',
            id: '4',
            number: '100004',
            name: 'Jim Red',
            count: '3',
            status: 'ยกเลิก',
            credit: 32000,
        },
    ]
}

const columns = [
    {
        title: '#',
        render: (row: any, key: number) => row.id,
        width: '72px',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.id - b.id,
    },
    {
        title: 'เลขที่การสั่งซื้อ',
        render: (row: any) => row.number,
    },
    {
        title: 'ชื่อ - นามสกุล (ลูกค้า)',
        render: (row: any) => row.name,
    },
    {
        title: 'จำนวน',
        width: '10%',
        render: (row: any) => row.count,
    },
    {
        title: 'สถานะ',
        width: '20%',
        render: (row: any) => row.status,
    },
    {
        title: 'รางวัล (เครดิต)',
        render: (row: any) => {
            return NumberWithCommas(row.credit)
        },
    }
]

const Reward = () => {
    return (
        <>
            <div className="pt-3 pt-lg-5">
                <div className="card-content">
                    <div className="row">
                        <div className='col-12'>
                            <h3 className='header-title'>บิลที่ถูกรางวัล</h3>
                        </div>
                    </div>
                </div>
            </div>

            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <Table
                    columns={columns as any}
                    dataSource={dataOjects.data}
                    rowKey={record => record.id}
                    // loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                // onChange={ScrollToTopPagination}
                // pagination={{
                //     defaultPageSize: 20,
                //     showSizeChanger: true,
                //     pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                //     position: ['topRight', 'bottomRight'],
                //     locale: { items_per_page: " รายการ" }
                // }}
                />
            </ListTableCard>
        </>
    )
}

export default Reward

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`