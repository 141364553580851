import useSWR from 'swr'

import serviceAPI from '../services/serviceAPI'
import { PaginationParam } from '../@types/pagination'

export const useFetchOrder = (params?: PaginationParam) => {
	const { data, mutate, error } = useSWR([`v1/mm/orders?limit=${params?.limit}&page=${params?.page}`, params], () =>
		serviceAPI.getOrder(params?.limit, params?.page)
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchOrder
