import React, { FC } from 'react'
import {
    Drawer,
    Button,
    Space,
    Descriptions,
} from 'antd';

interface ViewUserProps {
    order: any
    closeViewDrawer: () => void
    isOpenViewDrawer: boolean
    swapDrawerEdit: () => void
}

const ViewUser: FC<ViewUserProps> = ({ closeViewDrawer, isOpenViewDrawer, swapDrawerEdit, order }) => {
    const nameTitle = "รายละเอียด"

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeViewDrawer}
                visible={isOpenViewDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeViewDrawer} size="large">ปิด</Button>
                    </Space>
                }
            >
                <Descriptions title={nameTitle} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                    <Descriptions.Item label="ชื่อ - นามสกุล">{order.name}</Descriptions.Item>
                    <Descriptions.Item label="เบอร์โทรติดต่อ">{order.phone}</Descriptions.Item>
                    <Descriptions.Item label="ที่อยู่ติดต่อ">{order.contact}</Descriptions.Item>
                    <Descriptions.Item label="จำนวนที่ซื้อ">{order.totalNumber}</Descriptions.Item>
                    <Descriptions.Item label="หมายเลขที่ซื้อ">
                        {order.numbers !== undefined &&
                            order.numbers.map((number: any, key: number) => (
                                <>
                                    | {number} |
                                    <br />
                                </>
                            ))
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="จำนวนเงิน">{order.totalPrice}</Descriptions.Item>
                    <Descriptions.Item label="สถานะ">
                        {order.status === 'NEW' &&
                            <p style={{ color: '#ffc107' }}>รอการยืนยัน</p>
                        }
                        {order.status === 'Paid' &&
                            <p style={{ color: '#198754' }}>สำเร็จ</p>
                        }
                        {order.status === 'fail' &&
                            <p style={{ color: '#dc3545' }}>ยกเลิก</p>
                        }
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    )
}
export default ViewUser
