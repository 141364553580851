import { Button, Col, Form, Input, Row } from 'antd';
import React, { Fragment } from 'react'

import wallet from './../../assets/img/icon/wallet.svg'
import withdraw from './../../assets/img/icon/withdraw.svg'

import AuthService from '../../services/auth'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { NumberWithCommas } from '../../services/function';

const Home = () => {

  const userState = useSelector((state: ApplicationState) => state.user)

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const goToPage = (path: string) => {
    navigate(path)
  }

  const logOut = async () => {
    AuthService.revokeToken()
    navigate('/login')
  }

  const handelLogin = () => {
    Swal.fire({
      title: 'ออกจากระบบ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.isConfirmed) {
        logOut()
      }
    })
  }

  return (
    <Fragment>
      <div className="pt-3 pt-lg-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="card-credit-balance mb-4 flex-wrap">
              <div className='icon-wallet'>
                <img src={wallet} alt="logo Expense" />
              </div>
              <div className='text-wallet'>
                <h2>฿ {NumberWithCommas(userState?.info?.balance)}</h2>
                <p>เครดิต คงเหลือ</p>
                {/* <span>ข้อมูลล่าสุดเมื่อ</span> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card-credit-withdraw mb-4 flex-wrap">
              <div className='icon-wallet'>
                <img src={withdraw} alt="logo Expense" />
              </div>
              <div className='text-wallet'>
                <h2>฿ {NumberWithCommas(userState?.info?.balance)}</h2>
                <p>เครดิตที่ถอนได้ คงเหลือ</p>
                {/* <span>ข้อมูลล่าสุดเมื่อ</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body">
        <Form
          form={form}
          name="urlForm"
        >
          <Row gutter={24}>
            <Col span={20}>
              <Form.Item
                name="url"
                hasFeedback
              >
                <Input style={{ height: 50 }} placeholder="https://ลิ้งให้ผู้ซื้อเลือกเบอร์เอง" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <div className="text-end">
                <button type="submit" className="btn btn-primary"><i className="far fa-money-bill-wave-alt"></i> คัดลอก</button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <div className='list-menu'>
        <Button className='btn btn-outline-primary button-menu' onClick={() => { goToPage('/number') }}>ออกบิลใหม่</Button>
        <Button className='btn btn-outline-primary button-menu' onClick={() => { goToPage('/order') }}>บิลที่สั่งซื้อ</Button>
        <Button className='btn btn-outline-primary button-menu' onClick={() => { goToPage('/reward') }}>บิลที่ถูกรางวัล</Button>
        <Button className='btn btn-outline-primary button-menu' onClick={() => { goToPage('/history') }}>ประวัติการใช้เครดิต</Button>
        <Button className='btn btn-outline-primary button-menu' onClick={() => { goToPage('/deposit') }}>เติมเครดิต</Button>
        <Button className='btn btn-outline-primary button-menu' onClick={() => { goToPage('/withdraw') }}>ถอนเครดิต</Button>
        <Button className='btn btn-outline-primary button-menu'>ข้อมูลส่วนตัว</Button>
      </div>
      <Row gutter={24} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Button style={{ width: '100%', height: 100 }} type="primary" danger onClick={() => { handelLogin() }}>ออกจากระบบ</Button>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Home