import React from 'react'

import { Col, Form, Input, Row } from 'antd'
import { NumberWithCommas } from '../../services/function';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const Withdraw = () => {

    const [form] = Form.useForm();

    const userState = useSelector((state: ApplicationState) => state.user)

    const hendalWithdraw = async () => {
        form.validateFields()
            .then(async (values: any) => {
                try {
                    console.log(values);
                } catch ({ message }) {
                    console.log(message);
                }
            }).catch(({ message }: any) => {
                console.log(message);
            });
    }

    return (
        <>
            <div className="pt-3 pt-lg-5">
                <div className="card-content">
                    <div className="row">
                        <div className='col-12'>
                            <h3 className='header-title'>ถอนเครดิต</h3>
                        </div>
                    </div>
                </div>

                <div className="card-deposit-withdraw">
                    <Form
                        form={form}
                        name="urlForm"
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="amount"
                                    help={`จำนวนเครดิตที่ถอนได้ ${NumberWithCommas(userState?.info?.balance)}`}
                                    rules={[{ required: true, message: '' }, { type: 'string', min: 3, max: 3 }]}
                                >
                                    <Input style={{ height: 50 }} placeholder="กรอกจำนวนเครดิต" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <div className="text-end" style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '20px' }}>
                                    <button type="submit" className="btn btn-primary" onClick={hendalWithdraw}><i className="far fa-money-bill-wave-alt"></i> ยืนยัน</button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Withdraw