import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { requestUserDone, requestUserFailed, setUser } from './user.actions'

export interface State {
	isLoaded: boolean
	info?: any
	username?: string
}

export const defaultState: State = {
	isLoaded: false,
	info: undefined,
	username: undefined,
}

export default reducerWithInitialState<State>(defaultState)
	.cases([setUser, requestUserDone], (state, payload) => ({
		...state,
		isLoaded: true,
		info: payload.user,
		username: payload.username,
	}))
	.case(requestUserFailed, (state) => ({
		...state,
		isLoaded: true,
		info: undefined,
	}))
	.default((state) => state)
