import React, { Fragment } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AuthContainer from '../Auth/Authentication'
import Home from '../../views/Home'
import Number from '../../views/Number'
import Order from '../../views/Order'
import History from '../../views/History'
import Reward from '../../views/Reward'
import Deposit from '../../views/Deposit'
import Withdraw from '../../views/Withdraw'

const Main = () => {
    return (
        <AuthContainer>
            <Fragment>
                <div className="app">
                    <div className="content">
                        <div className="container">
                            <Routes>
                                <Route path="/home" element={<Home />} />
                                <Route path="/number" element={<Number />} />
                                <Route path="/order" element={<Order />} />
                                <Route path="/reward" element={<Reward />} />
                                <Route path="/history" element={<History />} />
                                <Route path="/deposit" element={<Deposit />} />
                                <Route path="/withdraw" element={<Withdraw />} />
                                <Route path="*" element={<Navigate to="/home" replace />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </Fragment>
        </AuthContainer>
    )
}

export default Main